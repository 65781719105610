var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.conversationSize && _vm.isFetchingList)?_c('div',{staticClass:"flex items-center justify-center flex-1 h-full bg-black-25",class:{ dark: _vm.prefersDarkMode }},[_c('Spinner',{attrs:{"size":""}})],1):_c('div',{staticClass:"flex flex-col justify-end h-full",class:{
    'is-mobile': _vm.isMobile,
    'is-widget-right': _vm.isRightAligned,
    'is-bubble-hidden': _vm.hideMessageBubble,
    'is-flat-design': _vm.isWidgetStyleFlat,
    dark: _vm.prefersDarkMode,
  }},[_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }